import React from "react"
import Layout from "../../components/layout"
import tomato from "../../../static/tomato.png"
import { Link } from "gatsby"
export default () => <Layout>
    <Link to={"../nutrition"}><h2>Plant Nutrition and Deficiencies</h2></Link>
    <h4>Phosphorus</h4>
    <p></p>
<h4>Functions</h4>
<p> </p>
<p> </p>

<p> </p>
<p> </p>

<h4>Deficiency Symptoms</h4>
<p></p>
<p> </p>
<p></p>
<p></p>
<p> </p>
<p></p>
<p></p>

<h4>Management</h4>
<p></p>
<p> </p>
<p> </p>
    </Layout>